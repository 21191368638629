<template>
  <label :class="classes">
    <span v-if="props.label" class="label">
      {{ props.label }}
    </span>

    <div class="row">
      <input
        class="field"
        :type="type"
        :readonly="props.isDisabled"
        :name="props.name"
        :required="props.isRequired"
        :placeholder="props.placeholder || ''"
        :value="props.value"
        @focus="onFocus"
        @blur="onBlur"
        @input="onInput"
        @keyup.enter="emit('submit', $event)"
      />
      <button-toggle-type @change-type="changeType" />
    </div>

    <atomic-hint v-if="!props.useCustomValidator && props.hint" v-bind="props.hint" />
    <sign-up-password-validation
      v-if="displayPasswordValidation && props.useCustomValidator"
      ref="passwordValidator"
      :value="props.value || ''"
      @has-errors="hasErrors = $event"
    />
  </label>
</template>

<script setup lang="ts">
  const props = defineProps<{
    name: string;
    value?: string;
    label?: string;
    placeholder?: string;
    isDisabled?: boolean;
    hint?: { variant: string; message: string };
    showPassProgress?: boolean;
    useCustomValidator?: boolean;
  }>();

  const classes = computed(() => [
    'input-password',
    { 'has-error': props.hint?.variant === 'error' },
    { 'is-disabled': props.isDisabled },
  ]);

  const displayPasswordValidation = ref<boolean>(false);

  const emit = defineEmits(['blur', 'focus', 'input', 'update:value', 'submit', 'has-errors']);
  const onBlur = (e: any): void => {
    emit('blur', e.target.value);
  };
  const onFocus = (e: any): void => {
    displayPasswordValidation.value = true;
    emit('focus', e.target.value);
  };

  const onInput = (e: any): void => {
    emit('input', e.target.value);
    emit('update:value', e.target.value);
  };

  const type = ref<string>('password');
  const changeType = (data: string): void => {
    type.value = data;
  };

  const hasErrors = ref<boolean>(false);

  watch(
    () => hasErrors.value,
    newValue => {
      if (newValue) {
        emit('has-errors', true);
      } else {
        emit('has-errors', false);
      }
    }
  );

  const passwordValidator = ref();

  watch(
    () => props.hint?.variant,
    newValue => {
      if (newValue === 'error') {
        passwordValidator.value?.validatePassword(props.value || '');
      }
    }
  );
</script>

<style src="~/assets/styles/components/form/input/password.scss" lang="scss" />
