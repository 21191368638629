<template>
  <div class="password-validation">
    <div
      class="password-validation__item"
      :class="{ initial: errors.empty === null, valid: errors.empty === false, invalid: errors.empty === true }"
    >
      {{ getContent(fieldsSettings, defaultLocaleFieldsSettings, 'validationMessages.empty') }}
    </div>
    <div
      class="password-validation__item"
      :class="{ initial: errors.spaces === null, valid: errors.spaces === false, invalid: errors.spaces === true }"
    >
      {{ getContent(fieldsSettings, defaultLocaleFieldsSettings, 'validationMessages.spaces') }}
    </div>
    <div
      class="password-validation__item"
      :class="{ initial: errors.latin === null, valid: errors.latin === false, invalid: errors.latin === true }"
    >
      {{ getContent(fieldsSettings, defaultLocaleFieldsSettings, 'validationMessages.latin') }}
    </div>
    <div
      class="password-validation__item"
      :class="{ initial: errors.length === null, valid: errors.length === false, invalid: errors.length === true }"
    >
      {{ minCharactersMessage }}
    </div>
  </div>
</template>

<script setup lang="ts">
  import { ref, watch, computed } from 'vue';
  import fieldsTypeMap from '~/maps/fieldsTypeMap.json';
  import { passwordEmpty, passwordSpaces, passwordLatin, passwordLength } from '~/composables/validationRules';
  const { getContent } = useProjectMethods();
  const globalStore = useGlobalStore();
  const { fieldsSettings, defaultLocaleFieldsSettings } = storeToRefs(globalStore);

  const props = defineProps<{
    value: string;
  }>();

  const errors = ref<{
    empty: boolean | null;
    spaces: boolean | null;
    latin: boolean | null;
    length: boolean | null;
  }>({
    empty: null,
    spaces: null,
    latin: null,
    length: null,
  });

  const validationRulesMap: Record<string, (value: string) => boolean> = {
    passwordEmpty,
    passwordSpaces,
    passwordLatin,
    passwordLength,
  };

  const getValidationRules = (fieldName: string) => {
    const fieldConfig = fieldsTypeMap[fieldName];
    return fieldConfig.validation.map((ruleConfig: { rule: string }) => validationRulesMap[ruleConfig.rule]);
  };

  const rules = getValidationRules('password_registration');

  const validatePassword = (password: string) => {
    errors.value.empty = !rules[0](password);

    if (errors.value.empty) {
      errors.value.spaces = null;
      errors.value.latin = null;
    } else {
      errors.value.spaces = !rules[1](password);
      errors.value.latin = !rules[2](password);
    }

    errors.value.length = !rules[3](password);

    const hasErrors = Object.values(errors.value).some(error => error === true);
    emit('has-errors', hasErrors);
  };

  const emit = defineEmits(['has-errors']);

  watch(
    () => props.value,
    newPassword => {
      validatePassword(newPassword);
    }
  );

  defineExpose({
    validatePassword,
  });

  const minCharactersMessage = computed(() => {
    return getContent(
      fieldsSettings.value,
      defaultLocaleFieldsSettings.value,
      'validationMessages.minCharacters'
    )?.replace('{param}', '6');
  });
</script>

<style src="~/assets/styles/components/password-validation.scss" lang="scss" />
