<template>
  <span class="btn-toggle-type" @click.prevent="toggle">
    <atomic-icon :id="icon" />
  </span>
</template>

<script setup lang="ts">
  const items = {
    text: 'visibility-on',
    password: 'visibility-off',
  };
  const hide = ref<boolean>(true);
  const type = computed(() => Object.keys(items)[Number(hide.value)]);
  const icon = computed(() => Object.values(items)[Number(hide.value)]);

  const emit = defineEmits(['change-type']);
  const toggle = (): void => {
    hide.value = !hide.value;
    emit('change-type', type.value);
  };
</script>

<style src="~/assets/styles/components/button/toggle-type.scss" lang="scss" />
